<template>
    <router-view />
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();
        const loading = computed(
            () => store.state.palveluyksikotLoading || store.state.organisationsLoading
        );
        const fetchPalveluyksikot = () => store.dispatch("fetchPalveluyksikot");
        const fetchOrganisations = () => store.dispatch("fetchOrganisations");
        onMounted(fetchOrganisations);
        onMounted(fetchPalveluyksikot);

        return { loading };
    },
});
</script>
